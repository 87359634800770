
import React from 'react';
import './brokers.css'
import GoogleAnalytics from '../../Gooleads/gleanalytics';

export default function Zerodha() {
  return (
    <div>
      <GoogleAnalytics/>
      <h2>Zerodha - Brokerage Charges</h2>
      <h3>Equity</h3>
      <table>
        <thead>
          <tr>
            <th>Zerodha Charges</th>
            <th>Equity delivery</th>
            <th>Equity intraday</th>
            <th>F&O - Futures</th>
            <th>F&O - Options</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Brokerage</td>
            <td>Zero Brokerage</td>
            <td>0.03% or Rs. 20/executed order whichever is lower</td>
            <td>0.03% or Rs. 20/executed order whichever is lower</td>
            <td>Flat Rs. 20 per executed order</td>
          </tr>
          <tr>
            <td>STT/CTT</td>
            <td>0.1% on buy & sell</td>
            <td>0.025% on the sell side</td>
            <td>0.0125% on the sell side</td>
            <td>
              0.125% of the intrinsic value on options that are bought and exercised
              <br />
              0.0625% on sell side (on premium)
            </td>
          </tr>
          <tr>
            <td>Transaction charges</td>
            <td>NSE: 0.00325%<br />BSE: 0.00375%</td>
            <td>NSE: 0.00325%<br />BSE: 0.00375%</td>
            <td>NSE: 0.0019%<br />BSE: 0</td>
            <td>NSE: 0.05% (on premium)<br />BSE: 0.005% (on premium)</td>
          </tr>
          <tr>
            <td>GST</td>
            <td>18% on (brokerage + SEBI charges + transaction charges)</td>
            <td>18% on (brokerage + SEBI charges + transaction charges)</td>
            <td>18% on (brokerage + SEBI charges + transaction charges)</td>
            <td>18% on (brokerage + SEBI charges + transaction charges)</td>
          </tr>
          <tr>
            <td>SEBI charges</td>
            <td>₹10 / crore</td>
            <td>₹10 / crore</td>
            <td>₹10 / crore</td>
            <td>₹10 / crore</td>
          </tr>
          <tr>
            <td>Stamp charges</td>
            <td>0.015% or ₹1500 / crore on buy side</td>
            <td>0.003% or ₹300 / crore on buy side</td>
            <td>0.002% or ₹200 / crore on buy side</td>
            <td>0.003% or ₹300 / crore on buy side</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
