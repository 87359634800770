
import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './header.css';
import GoogleAnalytics from '../../Gooleads/gleanalytics';
// import Home from '../../components/home/home';
// import Swing from '../../components/swing/swing';
// import { Nav } from 'react-bootstrap';


const Header = () => {
    return(
      <>
      <GoogleAnalytics/>
      <div className='h'>
      <Link className='header' to='/home'>Home</Link>
      <Link className='header' to='/Swing '>SwingTrads</Link>
      {/* <Link className='header' to='/longterm'>Long Term</Link>
      <Link className='header' to='/IPO'>IPO</Link> */}
      <Link className='header' to='/brokers'>Brokers</Link>
      <Link className='header' to='/Technology'>Technology</Link>
      </div>
      </>
      )
};

export default Header;


