import React, { useEffect } from 'react';

const GoogleAdSense = () => {
  useEffect(() => {
    // Load the AdSense ads
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <ins className="adsbygoogle"
         style={{ display: 'block' }}
         data-ad-client="ca-pub-7378318626163212"
         data-ad-slot="9834588378"
         data-ad-format="auto"
         data-full-width-responsive="true">
    </ins>
  );

  return null; // This component doesn't render anything
}

export default GoogleAdSense;
