import React from 'react';
import { Helmet } from 'react-helmet';
import './frontend.css'
import GoogleAdSense from '../../Gooleads/ads';

export default function Frontend() {
  return (
    <div>
      <Helmet>
        <title>Top 5 frontend free courses</title>
        <meta name="description" content="Description of my React app." />
      </Helmet>
      <h1>Top 5 frontend free courses</h1>
      <p>With the rapid expansion of the digital economy, there is an increasing demand for websites, web applications, and mobile apps. Frontend development is crucial for creating visually appealing and user-friendly interfaces for these digital products.s</p>
      <ol>
        <h2><li>learn HTML,CSS</li></h2>
            <b className='content'>Content :</b>
                <ul className='ul'>Getting to Know HTML</ul>
                <ul className='ul'>Getting to Know CSS</ul>
                <ul className='ul'>Opening the Box Model</ul>
                <ul className='ul'>Working with Typography</ul>
                <ul className='ul'>Setting Backgrounds & Gradients</ul>
                <ul className='ul'>Creating Lists</ul>
                <ul className='ul'>Adding Media</ul>
                <ul className='ul'>Building Forms</ul>
                <ul className='ul'>Organizing Data with Tables</ul>
            <a href='https://learn.shayhowe.com/html-css/'>Click here to Start learning HTML and CSS</a>
            <GoogleAdSense/>
        <h2><li>HTML, CSS, and Javascript for Web Developers</li></h2>
            <b className='content'>Content :</b>
                <ul className='ul'>Introduction to HTML5</ul>
                <ul className='ul'>Introduction to CSS3</ul>
                <ul className='ul'>Coding the static Restaurant site</ul>
                <ul className='ul'>Introduction to Javascript</ul>
                <ul className='ul'>Build js to build responsible website</ul>
            <a href='https://www.coursera.org/learn/html-css-javascript-for-web-developers'>Click here to Start learning HTML, CSS and JS</a>
            <GoogleAdSense/>
        <h2><li>learn React jss</li></h2>
            <b className='content'>Content :</b>
                <ul className='ul'>React Components</ul>
                <ul className='ul'>JSX for react</ul>
                <ul className='ul'>Style react components</ul>
                <ul className='ul'>states and props</ul>
                <ul className='ul'>React form</ul>
                <ul className='ul'>Routing</ul>
                <ul className='ul'>Redux and many more</ul>
            <a href='https://infyspringboard.onwingspan.com/web/en/app/toc/lex_10648877150323546000_shared/overview'>Click here to learn react js</a>
            <GoogleAdSense/>
        <h2><li>React Native</li></h2>
            <b className='content'>Content :</b>
                <ul className='ul'>Template</ul>
                <ul className='ul'>Interactive react components</ul>
                <ul className='ul'>Navigation</ul>
                <ul className='ul'>List View</ul>
                <ul className='ul'>Storage</ul>
                <ul className='ul'>Extending react Native</ul>
            <a href='https://infyspringboard.onwingspan.com/web/en/app/toc/lex_auth_01266748655902720053_shared/overview'>Click here to learn React Native</a>
            <GoogleAdSense/>
        <h2><li>Angular Js</li></h2>
                <b className='content'>Content :</b>
                <ul className='ul'>Template</ul>
                <ul className='ul'>Data Binding</ul>
                <ul className='ul'>Pipes</ul>
                <ul className='ul'>Nasted Components</ul>   
            <a href=''>Click here to learn Angular js</a>                                    
            <GoogleAdSense/>
      </ol>
      <p><b>Conclusion:</b><br/>
      India boasts a large pool of talented developers, thanks to its extensive network of engineering colleges, coding bootcamps, and online learning platforms. Many developers in India specialize in frontend technologies like HTML, CSS, JavaScript, and popular frontend frameworks like React.js, Angular.
      </p>
      {/* Rest of your app */}
    </div>
  )
}
