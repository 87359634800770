import React from 'react'
import GoogleAdSense from '../../Gooleads/ads'

export default function Aijobs() {
  return (
    <>
     <meta name="AI impact" content="Impact of AI on Tech Job Market: 80,000 Jobs Lost in May 2023"></meta>
    <h2 className='title'>Impact of AI on Tech Job Market: 80,000 Jobs Lost in May 2023</h2>
    <p className='details'>
    More than 80,000 individuals lost their positions in various areas worldwide in May 2023. Of these, approximately 4,000 programmers and geeks lost their jobs due to artificial intelligence (AI). With AI becoming more prevalent, career counselors suggest that it's time for developers to adapt.
      </p>
      <GoogleAdSense/>
    <p className='details'>
    According to a recent report, the tech job market has experienced significant volatility in recent years. The introduction of AI tools like ChatGPT, Poet, and Bing has further increased the challenges faced by professionals in this field. Since their inception, these three AI tools have made a significant impact on the tech industry, leading many companies to leverage AI for their benefit. Unfortunately, the growing influence of these emerging technologies resulted in approximately 4,000 job losses in May 2023 alone.
      </p>
    <GoogleAdSense/>
    <p className='details'>
    In February of the same year, a survey conducted by Resumebuilder.com revealed that certain companies in the US had begun implementing ChatGPT instead of human agents.
      </p>
    <p className='details'>
    According to a report by Business Insider, sourced from a monthly report by Challenger, Gray, and Christmas, the month of May saw a significant number of job losses, with approximately 4,000 people being affected by AI. The report also indicated that a total of 80,000 job cuts occurred during that month, with 3,900 of them directly attributed to AI. The remaining job cuts were attributed to various factors such as economic conditions, cost-cutting measures, company restructuring, and mergers and acquisitions.
      </p>
    <GoogleAdSense/>
    

    </>
  )
}
