import React from 'react';
import './body.css'
import { Routes, Route } from 'react-router-dom';
import Home from '../../components/home/home';
import Swing from '../../components/swing/swing';
import Brokers from '../../components/broker/broker';
import Upstox from '../../blogs/brokers/upstox';
import Zerodha from '../../blogs/brokers/zerodha';
import One from '../../blogs/swingtrads/One';
import Two from '../../blogs/swingtrads/two';
import Pvr from '../../blogs/swingtrads/pvr';
import GoogleAnalytics from '../../Gooleads/gleanalytics';
import Television from '../../blogs/swingtrads/television';
import Sbi from '../../blogs/swingtrads/sbi';
import Jiofin from '../../blogs/swingtrads/jiofin';
import Tatagroup from '../../blogs/swingtrads/tatagroup';
import Ibmlayoff from '../../blogs/technology/ibmlayoff';
import Tech from '../../components/tech/tech';
import Aijobs from '../../blogs/technology/aijobs';
import Aicourse from '../../blogs/technology/aicourse';
import Frontend from '../../blogs/technology/frontend';

export default function Body() {
  return (
    <div className='body'>
      <GoogleAnalytics/>
      <head>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7378318626163212"
     crossorigin="anonymous"></script>
      </head>
    <Routes>
      {/* This path is for header content */}
      <Route path='/' element={<Home/>}/>
      <Route path='/swing' element={<Swing/>} />
      <Route path='/home' element={<Home />} />
      <Route path='/brokers' element={<Brokers/>}/>
      <Route path='/technology' element={<Tech/>}/>
      {/* ------------------------------------------------------ */}
      {/* This path is for brokers content */}
      <Route path='/upstox' element={<Upstox/>}/>
      <Route path='/zerodha' element={<Zerodha/>}/>
      {/* ------------------------------------------------------ */}

    

      {/* --------------------------------------------------- */}
      {/* This path is for swing code */}
      <Route path='/swing/tatagroup' element={<Tatagroup/>}/>
      <Route path='/swing/jiofin' element={<Jiofin/>}/>
      <Route path='/swing/sbi' element={<Sbi/>}/>
      <Route path='/swing/television' element={<Television/>}/>
      <Route path='/swing/pvrinox' element={<Pvr/>}/>
      <Route path='/swing/yes bank' element={<One/>}/>
      <Route path='/swing/hindalco' element={<Two/>}/>


      {/* ------------------------------------------------------ */}

      {/* This path is for Long term stacks */}
     
      {/* ------------------------------------------------------ */}

      {/* Tech blogs */}
      <Route path='/tech/IBMLayoff' element={<Ibmlayoff/>}/>
      <Route path='/tech/Al-replace-humans' element={<Aijobs/>}/>
      <Route path='/tech/Aicourses' element={<Aicourse/>}/>
      <Route path='/tech/freefrontend' element={<Frontend/>}/>
    </Routes>
    
    </div>
  );
}
