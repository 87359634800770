// App.js
import React from 'react';

import Header from './pages/header/header';
import Footer from './pages/footer/footer';
import Body from './pages/body/body';
import ReactGA from 'react-ga';
import { useEffect } from 'react';
import AdSense from './Gooleads/adsense';
ReactGA.initialize('G-C3K6WJ7B37');


const App = () => {
  useEffect(() => {
   console.log(ReactGA.pageview(window.location.pathname + window.location.search));
  }, []);
  return (
    
   <>
   <AdSense/>
    <Header/>
    <Body/>
    <Footer/>
     
   </>
  );
};

export default App;
