import React from 'react'
import './footer.css'


export default function Footer() {
  return (
    <div className='footer'>
      ©SwingTrads 2024-25 All Rights Reserved
    </div>
  )
}
