
import React from 'react';
import GoogleAnalytics from '../../Gooleads/gleanalytics';


export default function Upstox() {
   
        return (
         
            <div className="upstox-container">
               <GoogleAnalytics/>
              <h2>Upstox - Brokerage Charges</h2>
              <h3>Equity</h3>
              <table>
                <thead>
                  <tr>
                    <th>Upstox Charges</th>
                    <th>Equity Delivery</th>
                    <th>Equity Intraday</th>
                    <th>Equity Futures</th>
                    <th>Equity Options</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Brokerage</td>
                    <td>₹20 or 2.5% (whichever is lower)</td>
                    <td>₹20 per executed order or 0.05% (whichever is lower)</td>
                    <td>₹20 per executed order or 0.05% (whichever is lower)</td>
                    <td>Flat ₹20 per executed order</td>
                  </tr>

                  <tr>
                    <td>STT/CTT (Upto 31st March 2023)</td>
                    <td>0.1% on buy & sell</td>
                    <td>0.025% on the sell side</td>
                    <td>0.01% on sell side</td>
                    <td>0.05% on sell side (on premium)</td>
                  </tr>

                  <tr>
                    <td>STT/CTT (From 1st April 2023)</td>
                    <td>0.1% on buy & sell</td>
                    <td>0.025% on the sell side</td>
                    <td>0.0125% on sell side</td>
                    <td>0.0625% on sell side (on premium)</td>
                  </tr>

                  <tr>
                    <td>GST</td>
                    <td>18% (on brokerage + transaction + demat charges)</td>
                    <td>18% (on brokerage + transaction charges)</td>
                    <td>18% (on brokerage + transaction charges)</td>
                    <td>18% (on brokerage + transaction charges)</td>
                  </tr>

                  <tr>
                    <td>Stamp Duty</td>
                    <td>0.015% or ₹1500 / crore on buy side</td>
                    <td>0.003% or ₹300 / crore on buy side</td>
                    <td>0.002% or ₹200 / crore on buy side</td>
                    <td>0.003% or ₹300 / crore on buy side</td>
                  </tr>

                  <tr>
                    <td>Transaction charges (Upto 31st March 2023)</td>
                    <td>NSE 0.00345% per trade on buy & sell. BSE charges vary as per the scrip group</td>
                    <td>NSE: 0.00345% per trade on buy & sell. BSE charges vary as per the scrip group</td>
                    <td>NSE Exchange turnover charge: 0.002%</td>
                    <td>NSE Exchange turnover charge: 0.053% (on premium)</td>
                  </tr>
                 
                </tbody>
              </table>
            </div>
          );
  }
    