import React from 'react'
import GoogleAnalytics from '../../Gooleads/gleanalytics'
import { Link } from 'react-router-dom'

export default function Tech() {
  return (
    <div>
        <div>
            <GoogleAnalytics/>
        <head><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7378318626163212"
     crossorigin="anonymous"></script></head>
     <Link className='tech' to='/tech/freefrontend'><li className='li'>Top 5 frontend free courses</li></Link>
     <Link className='tech' to='/tech/IBMLayoff'><li className='li'>IBM layOff in small meeting</li></Link>
     <Link className='tech' to='/tech/Al-replace-humans'><li className='li'>Impact of AI on Tech Job Market: 80,000 Jobs Lost in May 2023</li></Link>
      <Link className='tech' to='/tech/Aicourses'><li className='li'>3 Free Courses to Dominate Insights for Information Science/AI</li></Link>
        </div>
      
    </div>
  )
}
