import React from 'react'
import { Link } from 'react-router-dom';


export default function Brokers() {
  return (
    <div>
      <h1>Indias Top Brokers</h1>
      <div>
      <Link  to='/upstox'>Upstox : </Link>
        <p>Upstox provide zero brokerage and 20Rs per order charges on Delivary Trads </p>
      </div>
      <div>
      <Link  to='/zerodha'>Zerodha</Link>
        <p>Our ultra-fast flagship trading platform with streaming market data, advanced charts, an elegant UI, and more. Enjoy the Kite experience seamlessly on your Android and iOS devices.</p>
      </div>
      <div>
      <Link  to='/zerodha'>Grow</Link>
      </div>
      
    </div>
  )
}
  