import React from 'react'
import GoogleAdSense from '../../Gooleads/ads'
export default function Ibmlayoff() {
  return (
    <>
    <meta name="IBM Layoff" content="IBM Layoff: Impact on Marketing and Communications Divisions"></meta>
    <h2 className='title'>IBM Layoff: Impact on Marketing and Communications Divisions</h2>
    <p className='details'>
        IBM has joined the tech cutback club indeed this year as it declared laying off workers from its promoting and interchanges divisions, without referencing the specific number.</p>
    <p className='details'>
    IBM allegedly laid off representatives in the organization's advertising and correspondences division. The choice of cutbacks was uncovered in a seven-minute gathering by Jonathan Adashek, the main correspondences official at IBM, with the workers in the division. CNBC detailed IBM cutbacks refering to anonymous sources albeit the organization didn't deliver the data formally.
    </p>
    <GoogleAdSense/>
    <p className='details'>
    This comes as the organization's Chief Arvind Krishna said last year that IBM hopes to stop recruiting for jobs which could be supplanted with man-made brainpower (artificial intelligence) before long. Arvind Krishna said that recruiting in administrative center capabilities, for example, HR will be suspended or eased back. He said, "I could without much of a stretch see 30% of that getting supplanted by artificial intelligence and mechanization more than a five-year time frame."
    </p>
    <p className='details'>
    Something like 204 organizations including IBM have reported work cuts in 2024 according to layoffs.fyi site. These cutbacks have impacted 49,978 workers.
    </p>
    <GoogleAdSense/>
    
    </>
  )
}
