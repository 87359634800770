import React from 'react'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//import Home from '../../components/home/home';
import './home.css'
import ReactGA from 'react-ga';
import { useEffect } from 'react';
//import GoogleAdSense from '../../Gooleads/ads';
import GoogleAnalytics from '../../Gooleads/gleanalytics';

export default function Home() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (

    <div>
      
      <GoogleAnalytics/>
       <Helmet>
        <title>Stock Updates - Live</title>
        <meta name="description" content="Stay updated with the latest stock market news and trends. Get insights on PSU banks, WelsPunLiv, Bandhan Bank, EDELWISS, and TAJGVK group." />
      </Helmet>
      
    </div>
  )
}
  