import React from 'react';
import { Helmet } from 'react-helmet';

const AdSense = () => (
  <Helmet>
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7378318626163212" crossorigin="anonymous"></script>
  </Helmet>
);

export default AdSense;
