import React from 'react'
//import Example from '../../blogs/swingtrads/example'
import { Link } from 'react-router-dom';
import './swing.css'
import GoogleAnalytics from '../../Gooleads/gleanalytics';


export default function Swing() {
  return (
    <div>
     <div>
      <GoogleAnalytics/>
      <head><script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7378318626163212"
     crossorigin="anonymous"></script></head>
       <Link className='swing' to='/swing/tatagroup'> <li className='li'>Tata Group Shares Surge in March 2024: A Testament to Resilience and Innovation</li> </Link>
       <Link className='swing' to='/swing/jiofin'><li className='li'>Jio Finance Surges to New Heights: A Promising Outlook for Investors</li></Link>
      <Link className='swing' to='/swing/sbi'> <li className='li'>Seizing Opportunity: Analyzing SBI's Surge and Prospects</li> </Link> 
      <Link className='swing' to='/swing/television'><li className='li'>Promising Outlook for DB Corp and TV18 Shares Post 2024 Elections</li></Link>
      <Link className='swing' to='/swing/pvrinox'><li className='li'>Analyzing PVRINOX Stock: Short-Term Opportunity Amid Long-Term Uncertainty</li></Link>
      <Link className='swing' to='/swing/yes bank'> <li className='li'>The Future of Yes Bank Share: A Glimpse into Potential Growth</li> </Link>
      {/* <Link className='swing' to='/swing/yes Bank'> <li className='li'>What is the future of yes bank share?</li> </Link> */}
      <Link className='swing' to='/swing/hindalco'> <li className='li'>Hindalco's Share Price Declines Despite Strong Q3 Results</li> </Link>
     
      </div>
      
      {/* <a target="_blank" href="https://www.amazon.in/b?_encoding=UTF8&tag=swingtrads-21&linkCode=ur2&linkId=1ad20acabef7b03a9302fd1c60a43f23&camp=3638&creative=24630&node=976419031">Laptop</a> */}
    </div>
  )
}
